<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Auto login ...
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="12"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            src="@/assets/images/pages/login-v2-dark.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BImg, VBTooltip } from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility, general],
  data() {
    return {}
  },
  created() {
    this.me()
  },
  methods: {
    async me() {
      const srtToken = this.$route.query.token
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/me/profile`,
          {
            headers: {
              Authorization: `Bearer ${srtToken}`,
            },
          },
        )
        const res2 = await this.fSite(res.data.data.id)
        if (res.data.status === true) {
          localStorage.setItem('siteID', JSON.stringify(res2))
          localStorage.setItem('token', JSON.stringify(res.data.data))

          const param = {
            domain_name: res2.domain_name,
            template_id: res2.template_id,
            type: this.$route.query.type,
          }
          const res3 = await Request.post(
            this.$http,
            `${process.env.VUE_APP_CLIENT_API_URL}/auth/sync_template`,
            param,
          )
          if (res3.data.status === true) {
            this.$router.push('/')
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Synctemplate fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: 'Error User!',
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Error User!',
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Catch fail',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Error User!',
          },
        })
      }
    },
    async fSite(idU) {
      const srtToken = this.$route.query.token
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/sites?user_id=${idU}`,
          {
            headers: {
              Authorization: `Bearer ${srtToken}`,
            },
          },
        )
        if (res.data.status === false) {
          return false
        }
        return res.data.data.items[0]
      } catch (error) {
        return false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
